/* eslint-disable camelcase */
import dynamic from 'next/dynamic';
import getNextConfig from 'next/config';
import React, { useEffect } from 'react';
import { wrapper } from 'redux/createStore';
import { CookiesProvider, Cookies } from 'react-cookie';
import PropTypes from 'prop-types';
import includes from 'lodash.includes';
import { BEDROCK_API_ENABLED } from 'lib/brandFeatures';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';

import {
  disableLazyLoadAction,
  loadSocialMedia,
  setChromeless,
  setPlatform,
  setTaboolaFeed,
  setVertical,
  setView,
} from 'redux/modules/shared';
import { globalLoadComplete } from 'redux/modules/router';
import { setInitialTheme, setInitialNavbarLocal } from 'redux/modules/navbar';

import parseQueryString from 'lib/parseQuerystring';
import { isStartTodayUrl } from 'lib/startTodayUtils';
import {
  BreakpointContextProvider,
  RouteContext,
  VerticalContext,
  FeatureFlagContext,
} from 'lib/ContextTypes';
import { UseGammaVod } from 'lib/ContextTypes/useGammaVod';
import BTE from 'lib/BTE';
import { isEmbedPath } from 'lib/isEmbedPath';
import { preloadMarqueeEmbedContent } from 'lib/inlinePriorityEmbeds';
import { getDerivedHttpResponseStatusCodeFromPageProps } from 'lib/getDerivedHttpResponseStatusCodeFromPageProps';
import { initializeDatadogRum } from 'lib/datadog';
import { initAds } from 'lib/Ads/initAds';
import { VIEW_LIST, VIEW } from 'lib/view';

import { useMyNewsStore, useBedrockRegistration } from 'store';

// Language configuration and initialization
import 'lib/Locale/i18n';
import { isBrowser } from 'lib/BrowserDetection';
import { AUTHENTICATED, UNAUTHENTICATED } from 'lib/myNewsConstants';
// eslint-disable-next-line import/no-unresolved
import '@nbcnews/omega-player/index.css';
import { Overlay } from 'components/AccountLoginRegistration/Overlay';
import { setLanguageForVertical } from 'lib/translation';

const AirshipWebNotificationsDynamic = dynamic(() => import('components/AirshipWebNotifications').then((module) => module.AirshipWebNotifications));

const {
  publicRuntimeConfig: {
    dev,
    ENABLE_THEME_PALETTE,
  },
} = getNextConfig();

let ThemingPalette;
if (process.env.NODE_ENV !== 'production' && ENABLE_THEME_PALETTE) {
  // eslint-disable-next-line global-require
  ThemingPalette = require('../src/components/ThemingPalette').default;
}

if (isBrowser() && !dev) {
  initializeDatadogRum();
}

/**
 * Retrieves cookies from the context.
 * @param {object} ctx - The context object.
 * @returns {object} The universalCookies object.
 */
const getCookies = (ctx) => {
  if (ctx?.req?.universalCookies) {
    return ctx.req.universalCookies;
  }
  return new Cookies();
};

if (process.env.PLAYWRIGHT_TEST && typeof window !== 'undefined') {
  // eslint-disable-next-line global-require
  const { worker } = require('../src/mocks/browser');
  worker.start();
}

/**
 * Main application component.
 * @param {object} props - The properties passed to the component.
 * @param {boolean} props.airshipWebNotificationsEnabled - Flag to enable Airship web notifications.
 * @param {React.ElementType} props.Component - The component to render.
 * @param {object} props.cookies - The cookies object.
 * @param {boolean} props.featureFlagQueryParam - Feature flag query parameter.
 * @param {boolean} props.useGammaVod - Flag to use Gamma VOD.
 * @param {object} props.launchDarklyFlags - Launch Darkly flags.
 * @param {object} props.pageProps - Page properties.
 * @returns {React.ReactElement} The rendered component.
 */
function App({
  airshipWebNotificationsEnabled,
  Component,
  cookies = {},
  featureFlagQueryParam = false,
  useGammaVod,
  pageProps,
  launchDarklyFlags,
}) {
  const setAuthentication = useMyNewsStore((state) => state.setAuthentication);
  const setIsBedrockApiEnabled = useMyNewsStore((state) => state.setIsBedrockApiEnabled);
  const getCustomer = useMyNewsStore((state) => state.getCustomer);
  const resetAuthentication = useMyNewsStore((state) => state.resetAuthentication);
  const refreshUserToken = useBedrockRegistration((state) => state.refreshUserToken);
  const customerLoadedRef = React.useRef();

  const {
    domain,
    hostname,
    path,
    statusCode,
    vertical,
  } = pageProps;

  setLanguageForVertical(vertical);

  const airshipNotificationsEnabled = !isEmbedPath(path) && airshipWebNotificationsEnabled;

  const { 'account-login': accountLogin } = launchDarklyFlags;
  const brandFeatureFlag = getFeatureConfigForBrand(
    BEDROCK_API_ENABLED,
    vertical,
  );
  const isBedrockApiEnabled = accountLogin && brandFeatureFlag;
  setIsBedrockApiEnabled(isBedrockApiEnabled);

  React.useEffect(() => {
    initAds();

    BTE.monitorScroll();
    BTE.monitorResize();


    return () => {
      BTE.deregisterScroll();
      BTE.deregisterResize();
    };
  }, []);

  useEffect(() => {
    const hfsHeader = document.getElementById('hfs-header');
    /**
     * Handles the identity state event.
     * @param {object} event - The event object.
     * @param {object} event.detail - The event detail object.
     * @param {string} event.detail.state - The state of the identity.
     * @returns {void}
     */
    async function handleIdentityStateEvent(event = {}) {
      // Set authenticationState in store
      const { state } = event.detail || {};

      if (state === AUTHENTICATED && !customerLoadedRef.current) {
        customerLoadedRef.current = true;
        // Set authenticationState from Identity SDK in state.
        setAuthentication(state);
        // Get customer data from mynewsapi
        getCustomer();
      }

      if (state === UNAUTHENTICATED) {
        customerLoadedRef.current = false;
        resetAuthentication();
      }
    }
    // If new login flow, restore session from cookies.
    // Now we could actually evaluate the logged in state on the server.
    // In order to do this we need to rethink the caching strategy on the CDN.
    if (isBedrockApiEnabled) {
      refreshUserToken();
    } else {
      // Listen to HFS.identity.state event
      hfsHeader?.addEventListener('HFS.identity.state', handleIdentityStateEvent);
    }

    return () => {
      if (!isBedrockApiEnabled) {
        hfsHeader?.removeEventListener('HFS.identity.state', handleIdentityStateEvent);
      }
    };
  }, []);


  return (
    <CookiesProvider cookies={isBrowser() ? undefined : cookies}>
      <VerticalContext.Provider value={vertical}>
        <FeatureFlagContext.Provider value={{ ...launchDarklyFlags, featureFlagQueryParam }}>
          <RouteContext.Provider
            value={{
              domain, hostname, path, statusCode,
            }}
          >
            <BreakpointContextProvider>
              <UseGammaVod.Provider value={useGammaVod}>
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <Component {...pageProps} />
                <Overlay />
              </UseGammaVod.Provider>
              {
                ThemingPalette && <ThemingPalette vertical={vertical} />
              }
              {airshipNotificationsEnabled ? (
                <AirshipWebNotificationsDynamic />
              ) : null}
            </BreakpointContextProvider>
          </RouteContext.Provider>
        </FeatureFlagContext.Provider>
      </VerticalContext.Provider>
    </CookiesProvider>
  );
}

App.propTypes = {
  airshipWebNotificationsEnabled: PropTypes.bool.isRequired,
  Component: PropTypes.elementType.isRequired,
  cookies: PropTypes.shape({
    cookies: PropTypes.shape({
      access_token: PropTypes.string,
    }),
  }),
  featureFlagQueryParam: PropTypes.bool,
  useGammaVod: PropTypes.bool,
  launchDarklyFlags: PropTypes.shape({
    'account-login': PropTypes.bool,
  }).isRequired,
  pageProps: PropTypes.shape({
    domain: PropTypes.string.isRequired,
    hostname: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    statusCode: PropTypes.number.isRequired,
    vertical: PropTypes.string.isRequired,
  }).isRequired,
};

App.getInitialProps = wrapper.getInitialAppProps(
  (store) => async (context) => {
    const {
      Component: PageComponent,
      ctx,
      ctx: {
        query: { page },
        req: {
          originalUrl: path,
          url,
        },
        res: {
          locals: {
            domain,
            getLaunchDarklyFlag,
            hostname,
            launchDarklyFlags,
            layout,
            vertical,
            fullUrl,
          } = {},
          statusCode,
        },
      },
    } = context;

    await store.dispatch(setVertical(vertical));

    /**
     * Fetch Launch Darkly feature flag by name while passing custom LDUser data
     * @param {object} params Parameters for fetching Launch Darkly feature flag
     * @param {string} params.flagName Name of the LD feature flag to retrieve
     * @param {object} params.customParams Custom data to pass to LD feature flag
     * @returns {boolean}
     */
    const getLaunchDarklyFlagWithCustomParams = ({ flagName = null, customParams = {} }) => (
      (!getLaunchDarklyFlag || !flagName)
        ? false
        : getLaunchDarklyFlag(flagName, (getLDUser) => getLDUser({
          custom: {
            pageType: page,
            vertical,
            fullUrl,
            ...customParams,
          },
        }))
    );

    const getAirshipWebNotifications = getLaunchDarklyFlagWithCustomParams({
      flagName: 'airship-web-notifications',
    });

    // need to add `store` to the context passed to each page component's `getInitialProps`. that
    // way the page component doesn't need to use `next-redux-wrapper`'s `getInitialPageProps` which
    // adds the store to the resulting props, thereby duplicating the store in the `__NEXT_DATA__`
    // in the markup sent to the client.
    const pageContext = { ...ctx, store };
    // pageProps is used in the render to provide props to the page component
    let pageProps = {};
    if (PageComponent.getInitialProps) {
      pageProps = await PageComponent.getInitialProps(pageContext);

      // Component.WrappedComponent needed to bypass redux connect wrapper
    } else if (PageComponent.WrappedComponent?.getInitialProps) {
      pageProps = await PageComponent.WrappedComponent.getInitialProps(pageContext);
    }
    pageProps.view = null;

    const pagePropsStatusCode = getDerivedHttpResponseStatusCodeFromPageProps(pageProps);
    if (pagePropsStatusCode >= 400) {
      context.ctx.res.statusCode = pagePropsStatusCode;
    }

    const featureFlagParam = context?.query?.featureFlag ?? false;
    // `featureFlagQueryParam` context should be used only for the Akamai-
    // added `featureFlag` query string param.
    // See this doc: https://nbcnewsdigital.atlassian.net/wiki/spaces/RAM/pages/2639953981/A+B+Tests
    const featureFlagQueryParam = !includes(featureFlagParam, 'false');

    const promises = [
      store.dispatch(setPlatform('ramen-nextjs')),
      store.dispatch(setInitialTheme(vertical)),
      store.dispatch(setInitialNavbarLocal({ vertical, featureFlagQueryParam })),
      store.dispatch(globalLoadComplete({
        domain,
        host: hostname,
        navigating: pageProps.navigating || false,
        path,
      })),
    ];

    // Query string params
    const {
      chromeless,
      lazyload,
      taboolafeed,
    } = (path && parseQueryString(path)) || {};

    // Set view to chromeless on Start today app path and Start Today App view
    const isStartTodayApp = isStartTodayUrl(url) || VIEW_LIST.includes(chromeless?.toLowerCase());
    if (isStartTodayApp) {
      promises.push(store.dispatch(setChromeless()));
      promises.push(store.dispatch(setView(VIEW.START_TODAY_APP)));
      pageProps.view = VIEW.START_TODAY_APP;

      // chromeless=true - enable chromeless render
    } else if (chromeless?.toLowerCase() === 'true') {
      promises.push(store.dispatch(setChromeless()));
    }

    // lazyload=false - disable lazyload of images/components
    //   This is used for automated visual regression testing
    if (lazyload === 'false') {
      promises.push(store.dispatch(disableLazyLoadAction()));
    }
    // What is this used for?
    if (taboolafeed && taboolafeed.toLowerCase() === 'true') {
      promises.push(store.dispatch(setTaboolaFeed()));
    }

    // Load social media profiles
    promises.push(store.dispatch(loadSocialMedia(vertical)));

    const layoutPromise = preloadMarqueeEmbedContent(layout);

    await Promise.all(promises);

    if (!pageProps.namespacesRequired) {
      pageProps.namespacesRequired = [];
    }

    return {
      airshipWebNotificationsEnabled: await getAirshipWebNotifications,
      cookies: getCookies(context.ctx),
      featureFlagQueryParam,
      launchDarklyFlags,
      pageProps: {
        domain,
        hostname,
        host: hostname, // is this needed?
        layout: await layoutPromise,
        path,
        statusCode,
        vertical,
        ...pageProps,
      },
    };
  },
);

export default wrapper.withRedux(App);
